import * as React from 'react';
import {useCallback, useState, useEffect} from 'react';
import { 
  useAppDispatch, 
  useAppSelector 
} from '../../app/hooks';
import {
  setViewState,
  selectedMap
} from './mapSlice'


export default function Controls() {
  const {viewState} = useAppSelector(selectedMap);
  const dispatch = useAppDispatch();
  const [inputValue, setInputValue] = useState('');
  const [hasError, setError] = useState(false);

  useEffect(() => {
    setInputValue(`${viewState.longitude.toFixed(3)}, ${viewState.latitude.toFixed(3)}`);
    setError(false);
  }, [viewState]);

  const onChange = useCallback((evt: { target: { value: React.SetStateAction<string>; }; }) => {
    setInputValue(evt.target.value);
  }, []);

  const onSubmit = useCallback(() => {
    const [lng, lat] = inputValue.split(',').map(Number);
    if (Math.abs(lng) <= 180 && Math.abs(lat) <= 85) {
      dispatch(setViewState({longitude: lng, latitude: lat}));
      setError(false);
    } else {
      setError(true);
    }
  }, [dispatch, inputValue]);

  return (
    <div style={{padding: 12, fontFamily: 'sans-serif'}}>
      <span>MAP CENTER: </span>
      <input
        type="text"
        value={inputValue}
        onChange={onChange}
        style={{color: hasError ? 'red' : 'black'}}
      />
      <button onClick={onSubmit}>GO</button>
    </div>
  );
}
