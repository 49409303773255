import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
// import { mediaUserApi } from '../features/fye/MediaUserApi';
import gridReducer from '../features/grid/gridSlice';
import mapReducer from '../features/map/mapSlice';
import venuesReducer from '../features/venues/venuesSlice';
import mediaReducer from '../features/media/mediaSlice';
import mediaFiltersReducer from '../features/media/mediaFiltersSlice';
import chipSearchReducer from "../features/chipsSearch/chipSearchSlice"
import { mapApi } from '../features/map/mapApi';
import { venuesApi } from '../features/venues/venuesApi';
import { mediaApi } from '../features/media/mediaApi';

export const store = configureStore({
  reducer: {
    venues: venuesReducer,
    media: mediaReducer,
    mediaFilters: mediaFiltersReducer,
    grid: gridReducer,
    map: mapReducer,
    chipSearch: chipSearchReducer,
    [mapApi.reducerPath]: mapApi.reducer,
    [venuesApi.reducerPath]: venuesApi.reducer,
    [mediaApi.reducerPath]: mediaApi.reducer,
  },
  middleware: (getDefaultMiddleware) => {
    // getDefaultMiddleware().concat(mediaUserApi.middleware);
    return getDefaultMiddleware()
      .concat(mapApi.middleware)
      .concat(venuesApi.middleware)
      .concat(mediaApi.middleware);
  },
  devTools: process.env.NODE_ENV !== 'production',
});

// optional, but required for refetchOnFocus/refetchOnReconnect behaviors
// see `setupListeners` docs - takes an optional callback as the 2nd arg for customization
setupListeners(store.dispatch)

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
  >;
