import * as React from 'react';
import Controls from './features/map/controls'
import { Routes ,Route, BrowserRouter } from 'react-router-dom';
import {MapProvider} from 'react-map-gl';
import Media from './features/media/Media';
// import Venues from './features/venues/Venues';
import SearchAppBar from './features/appbar/Appbar';
import Box from '@mui/material/Box';
import Grid from './features/grid/Grid'
import Map from './features/map/Map';
import './App.css';



function FyeRoot() {
  return (
    <div>
    <SearchAppBar />
    <Box sx={{ order: 1, flex: 1, display: 'flex', flexDirection: 'column' }}>
    <MapProvider>
      <Controls />
      <Map />
    </MapProvider>
    </Box>
    <Box sx={{ order: 2, flex: 1, display: 'flex', flexDirection: 'column' }}>
      <Grid />
    </Box>
    <Media />
    </div>
  );
}

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<FyeRoot />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
