import { createSlice, createEntityAdapter, EntityState, PayloadAction, /* createSelector */ } from '@reduxjs/toolkit';
import type { RootState /* , store, */ } from '../../app/store';
// import _ from 'lodash'

export interface MenuItemFilter {
  MenuItem: string;
  subMenuItem_of: string;
  ids: number[];
};

export const MenuItemFiltersAdapter = createEntityAdapter<MenuItemFilter>({
  // Assume IDs are stored in a field `venue.id`
  selectId: (object: MenuItemFilter) => object.MenuItem,
  // Keep the "all IDs" array sorted based on venue distanst from the user
  sortComparer: (a, b) => 
    a.MenuItem.localeCompare(b.MenuItem, 'en', { numeric: false })
});

export interface mediaFiltersState {
  filters: any;
  menuItemsFiltersCount: number;
  mediaFiltersCount: number;
  requestedMediaFilters: string[];
  status: 'idle' | 'success' | 'error',
};

interface Id {
  id: number;
}

export interface Filter {
  index: string;
  subIndexOf: string;
  ids: EntityState<Id>;
};

export interface FilterType {
  name: string;
  filters: EntityState<Filter>;
};

export const IdAdapter = createEntityAdapter<Id>({
  // Assume IDs are stored in a field `venue.id`
  selectId: (object: Id) => object.id,
  // Keep the "all IDs" array sorted based on venue distanst from the user
  sortComparer: (a, b) => a.id.toString().localeCompare(b.id.toString(), 'en', { numeric: true })
});

export const FilterAdapter = createEntityAdapter<Filter>({
  // Assume IDs are stored in a field `venue.id`
  selectId: (object: Filter) => object.index,
  // Keep the "all IDs" array sorted based on venue distanst from the user
  sortComparer: (a, b) => a.index.localeCompare(b.index, 'en', { numeric: false })
});

export const FilterTypeAdapter = createEntityAdapter<FilterType>({
  // Assume IDs are stored in a field `venue.id`
  selectId: (object: FilterType) => object.name,
  // Keep the "all IDs" array sorted based on venue distanst from the user
  sortComparer: (a, b) =>  a.name.localeCompare(b.name, 'en', { numeric: false })
});

const initialState = {
  filters: FilterTypeAdapter.getInitialState(),
  menuItemsFiltersCount: 0,
  mediasFiltersCount: 0,
  requestedMediaFilters: [],
  status: 'idle',
} as unknown as mediaFiltersState;

export const mediaFiltersSlice = createSlice({
  name: 'mediaFilters',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setSuccess: (state) => {
      state.status = 'success'
    },
    setError: (state) => {
      state.status = 'error'
    },
    setRequestMediaFilters: (state, action: PayloadAction<any>) => {
      state.requestedMediaFilters = action.payload;
      // state.availableMediaFilters = _.union(state.entities.map((x: any) => x.filters));
    },
    // mediaFilterAdded: mediaFiltersAdapter.addOne,
    setAllMediaFilters: (state, action: PayloadAction<any>) => {
      // Or, call them as "mutating" helpers in a case reducer
      // MenuItemFiltersAdapter.setAll(state.menuItemsFilters, action.payload.entities);
      // state.mediaFiltersCount = state.menuItemsFilters.ids.length;
    },
    addManyMediaFilters: (state, action: PayloadAction<any>) => {


    },
    unionMediaFiltersIds: (state, action: PayloadAction<any>) => {
      // const venueIds = action.payload.ids;
      // const venueEntities = action.payload.entities;
      // state.menuItemsFilters.entities = state.menuItemsFilters.entities.map((filter: any) => )
    },
  },
});

// Can create a set of memoized selectors based on the location of this entity state
// export const mediaFilterSelector = mediaFiltersAdapter.getSelectors<RootState>(
//   (state) => state.menuItemsFilters
// );

// And then use the selectors to retrieve values
// export const { selectAll: selectAllMedia } = mediaFiltersAdapter.getSelectors<RootState>((state) => state.mediaFilters);
// export const { selectIds: selectMediaIds } = medaiFiltersAdapter.getSelectors<RootState>((state) => state.mediaFilters);
// export const { selectTotal: selectMediaTotal } = mediaFiltersAdapter.getSelectors<RootState>((state) => state.medaFilters);
// Custom Selecctors
// export const selectMediaDifference = createSelector(
//   [
//     selectMediaIds,
//     (state, media_ids) => media_ids
//   ],
//   // Output venue ids that are not downloaded (`selectVenueIds, venueIds)` as args
//   (ids, media_ids) => (_.difference(media_ids, ids))
// );
// export const selectMediaSubset = createSelector(
//   [
//     selectAllMedia,
//     (state, media_ids) => media_ids
//   ],
//   // Output selector gets (`selectAllVenues, venueIds)` as args
//   (media, media_ids) => (media.filter(item => media_ids.indexOf(item.id) !== -1))
// );

export const selectedMedia = (state: RootState) => state.mediaFilters;

// And then use the selectors to retrieve values
// const venuesBooks = venuesSelectors.selectAll(store.getState());

export const { setSuccess, setError, setRequestMediaFilters, addManyMediaFilters, setAllMediaFilters } = mediaFiltersSlice.actions;

export default mediaFiltersSlice.reducer;
