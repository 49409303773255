import {
  createApi,
  fetchBaseQuery,
} from '@reduxjs/toolkit/query/react';
import {
  EntityState,
} from '@reduxjs/toolkit'
import {
  Media,
  mediaAdapter,
} from './mediaSlice'


// export function fetchMedia(filters: string[], quanty: number | void) {
//   console.log("quanty", quanty)
//   console.log("filters", filters)
//   return new Promise<{ data: any }>((resolve) =>
//     setTimeout(() => resolve({ data: useFetchMediaQuery() }), 500)
//   );
// }

export const mediaApi = createApi({
  reducerPath: 'mediaApi',
  baseQuery: fetchBaseQuery({
    baseUrl: 'https://fyes.herokuapp.com/api/v1'
    // prepareHeaders(headers) {
    //    headers.set('x-api-key', DOGS_API_KEY);
    //
    //   return headers;
    // },
  }),
  tagTypes: ['Media'],
  endpoints: (builder) => ({
    // getMedia: builder.query<EntityState<Media>, number>({
    //   query: (id) => (`/media?ids=[${id}]`),
    //   providesTags: ['Media'],
    //   transformResponse(response: Media[]) {
    //     return mediaAdapter.addMany(mediaAdapter.getInitialState(), response);
    //   },
    // }),
    getMedia: builder.query<EntityState<Media>, number[] | void>({
      query: (ids) => ids ? (`/media?ids=[${ids.map((number) => ids)}]`) : `/media`,
      providesTags: ['Media'],
      transformResponse(response: Media[]) {
        return mediaAdapter.addMany(mediaAdapter.getInitialState(), response);
      },
    }),
    fetchMediaByLimit: builder.query<EntityState<Media>, number>({
      query: (limit) => (`/media?limit=${limit}`),
      providesTags: ['Media'],
      transformResponse(response: Media[]) {
        return mediaAdapter.addMany(mediaAdapter.getInitialState(), response)
      },
    }),
    fetchMediaByVeneuId: builder.query<EntityState<Media>, string>({
      query: (venueId) => (`/media?vids=[${venueId}]`),
      providesTags: ['Media'],
      transformResponse(response: Media[]) {
        return mediaAdapter.addMany(mediaAdapter.getInitialState(), response);
      },
    }),
    fetchMediaByVeneuIds: builder.query<EntityState<Media>, string[]>({
      query: (venueIds) => (`/media?vids=[${venueIds.map((id: string) => id)}]`),
      providesTags: ['Media'],
      transformResponse(response: Media[]) {
        const media = mediaAdapter.addMany(mediaAdapter.getInitialState(), response);
        console.log('results', media);
        return media;
      },
    }),
  }),
})


export const { useGetMediaQuery, useFetchMediaByLimitQuery, useFetchMediaByVeneuIdQuery, useFetchMediaByVeneuIdsQuery,  } = mediaApi;
