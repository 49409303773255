import _ from 'lodash'
import { createSlice, createEntityAdapter, createSelector, PayloadAction, EntityId } from '@reduxjs/toolkit';
import type { RootState } from '../../app/store'

export interface Venue {
  id: string;
  longitude: string;
  latitude: string;
  name: string;
  description: string;
  slug: string;
  phone: string;
  email: string;
  venue_hours: [];
  address: {};
  venue_types: [];
  cuisines: [];
  features: [];
  media_ids: [];
}

export const venuesAdapter = createEntityAdapter<Venue>({
  // Assume IDs are stored in a field `venue.id`
  selectId: (object: Venue) => object.id,
  // Keep the "all IDs" array sorted based on venue distanst from the user
  sortComparer: (a, b) => 
    (a.id.toString()).localeCompare(b.id.toString(), 'en', { numeric: true })
})

export const venuesSlice = createSlice({
  name: 'venues',
  initialState: venuesAdapter.getInitialState({
    venueCount: 0,
    requestedVenueIds: [],
    status: 'idle',
  }),
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setSuccess: (state) => {
      state.status = 'success'
    },
    setError: (state) => {
      state.status = 'error'
    },
    setRequestVenueIds: (state, action: PayloadAction<any>) => {
      // Or, call them as "mutating" helpers in a case reducer
      state.requestedVenueIds = action.payload;
    },
    venueAdded: venuesAdapter.addOne,
    venuesAdded: (state, action: PayloadAction<any>) => {
      // Or, call them as "mutating" helpers in a case reducer
      venuesAdapter.addMany(state, action.payload.entities);
      state.venueCount = state.ids.length;
    },
    upserVenue: (state, action: PayloadAction<any>) => {
      // Or, call them as "mutating" helpers in a case reducer
      venuesAdapter.upsertOne(state, action.payload.entities);
      state.venueCount = state.ids.length;
    },
    upserVenues: (state, action: PayloadAction<any>) => {
      // Or, call them as "mutating" helpers in a case reducer
      venuesAdapter.upsertMany(state, action.payload.entities);
      state.venueCount = state.ids.length;
    },
    venuesRecieved: (state, action: PayloadAction<any>) => {
      // Or, call them as "mutating" helpers in a case reducer
      venuesAdapter.setAll(state, action.payload.entities);
      state.venueCount = state.ids.length;
    },
    venuesDropped: (state, action: PayloadAction<any>) => {
      // Or, call them as "mutating" helpers in a case reducer
      venuesAdapter.removeMany(state, action.payload);
      state.venueCount = state.ids.length;
    },
  },
});

// const selectVenues = state => state.venues
// Can create a set of memoized selectors based on the location of this entity state
export const venuesSelectors = venuesAdapter.getSelectors<RootState>((state) => state.venues);

// And then use the selectors to retrieve values
// const venuesBooks = venuesSelectors.selectAll(store.getState());
export const { selectAll: selectAllVenues } = venuesAdapter.getSelectors<RootState>((state) => state.venues);
export const { selectIds: selectVenueIds } = venuesAdapter.getSelectors<RootState>((state) => state.venues);

// Custom Selecctors
export const selectVenueDifference = createSelector(
  [
    selectVenueIds,
    (state, venue_ids: EntityId[]) => venue_ids
  ],
  // Output venue ids that are not downloaded (`selectVenueIds, venueIds)` as args
  (ids, venue_ids) => (_.difference(venue_ids, ids))
);
export const selectVenueSubset = createSelector(
  [
    selectAllVenues,
    (state: any, venue_ids: EntityId[]) => venue_ids
  ],
  // Output selector gets (`selectAllVenues, venueIds)` as args
  (venues, venue_ids) => (venues.filter(item => venue_ids.indexOf(item.id) !== -1))
);

export const selectedVenues = (state: RootState) => state.venues;


export const { setSuccess, setError, setRequestVenueIds, venueAdded, venuesAdded, upserVenue, upserVenues, venuesRecieved, venuesDropped } = venuesSlice.actions;

export default venuesSlice.reducer;
