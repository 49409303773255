import React, {useCallback, useEffect} from 'react';
import useState from 'react-usestateref';
import {useMap} from 'react-map-gl';
// @mui
// import { styled } from '@mui/material/styles';
import styled from "styled-components";
import { 
  useAppDispatch, 
  useAppSelector 
} from '../../app/hooks';
import {
  selectedMap,
  setViewState,
  setBBoxState,
  updateVenueData,
  setResults,
} from './mapSlice'

//API section
import { setRequestMediaIds } from '../media/mediaSlice'
import { setRequestVenueIds, setSuccess, setError, selectedVenues, upserVenues, selectVenueDifference, selectVenueSubset, /* Venue, venuesAdapter */ } from '../venues/venuesSlice'
import { useGetMapObjectIdsByBoundsQuery } from './mapApi'
import { useFetchVenuesByIdsQuery } from './../venues/venuesApi';

//redux
import { skipToken } from '@reduxjs/toolkit/query/react'
import { store } from '../../app/store'

// Map Themes
import MapMarkersPopups from './MapMarkersPopups';

// Environment Vars
import { MAP_API } from '../../config-global'
// CSS
import 'mapbox-gl/dist/mapbox-gl.css';
// Consider making this ad evenironment val
const precision = 6;
const debug = false;
// -----------------------------------------------


const THEMES = {
  streets: 'mapbox://styles/mapbox/streets-v11',
  outdoors: 'mapbox://styles/mapbox/outdoors-v11',
  light: 'mapbox://styles/mapbox/light-v10',
  dark: 'mapbox://styles/mapbox/dark-v10',
  satellite: 'mapbox://styles/mapbox/satellite-v9',
  satelliteStreets: 'mapbox://styles/mapbox/satellite-streets-v11',
};

const baseSettings = {
  mapboxAccessToken: MAP_API,
  minZoom: 1,
};

// const StyledMapContainer = styled('div')(({ theme }) => ({
//   zIndex: 0,
//   height: 700,
//   overflow: 'hidden',
//   position: 'relative',
//   '& .mapboxgl-ctrl-logo, .mapboxgl-ctrl-bottom-right': {
//     display: 'none',
//   },
// }));

const StyledMapContainer = styled.div.attrs((/* props */) => ({
tabIndes: 0 }))`
position: fixed; no-repeat;
top: 63px;
bottom: 0;
left: 0;
right: 0;
width: 50%;
.mapboxgl-ctrl-logo, .mapboxgl-ctrl-bottom-right': {
    display: 'none',
  }
`

export default function MapView() {
  const dispatch = useAppDispatch();
  const {fyeMap} = useMap();
  // const [hasError, setError] = useState(false);
  const [bBox, setBBox, bBoxRef] = useState([[0, 0], [0, 0]]);
  const [mapBbox, setMapBbox, mapBboxRef] = useState([[0, 0], [0, 0]]);
  const { data: venues } = useAppSelector(selectedMap);
  const  { viewState } = useAppSelector(selectedMap);
  const [liveVenues, setLiveVenues, liveVenueRef ] = useState<any>(null);
  const [init, setInit] = useState(true);
  const [skip, setSkip] = useState(true);


  const onMove = useCallback((evt: { viewState: any; })  => {
    dispatch(setViewState(evt.viewState));
    if (fyeMap) {
      setBBox(fyeMap.getBounds().toArray());
      if (debug) console.log(bBox);
      // dispatch(setBBoxState(bBoxRef.current));
      setMapBbox( bBoxRef.current.map((el: any) => el.map((el: any) => el.toFixed(precision)))); //tune precission up top
      if (debug) console.log(mapBbox);
    }
  }, [dispatch,fyeMap, bBoxRef, setBBox, setMapBbox, bBox, mapBbox]);

  useEffect(() => {
    if (fyeMap) {
      setBBox(fyeMap.getBounds().toArray());
      if (debug) console.log(bBox);
      dispatch(setBBoxState(bBoxRef.current));
      if (init) setMapBbox( bBoxRef.current.map((el: any) => el.map((el: any) => el.toFixed(precision)))); //tune precission up top
      setInit(false);
      setSkip(false);
    };
  }, [init, dispatch, fyeMap, bBoxRef, setBBox, setMapBbox, bBox]);
  
  // Test viewpoart for venues and assoicated media
  const { data: ids, isSuccess } = useGetMapObjectIdsByBoundsQuery( mapBboxRef.current, { skip });
  useEffect(() => {
    if (isSuccess) {
      dispatch(setResults(ids)); //this is just for the informational purposes could be commented out may be useful for cache expiration
      dispatch(setRequestVenueIds(ids.venue_ids));
      dispatch(setRequestMediaIds(ids.media_ids));
    };
  }, [dispatch, isSuccess, ids, bBoxRef, setBBox, setMapBbox]);
//  console.log(mapBboxRef.current);

///Venue stuff start
const GetVenues = () => {
  const [diff, setDiff, diffRef] = useState([]);
  const { requestedVenueIds } = useAppSelector(selectedVenues);

  if (debug) console.log('requestedVenueIds',requestedVenueIds);

  useEffect(() => {
    // @ts-ignore
    setDiff(selectVenueDifference(store.getState(), requestedVenueIds));
  });
  if (debug) console.log('diff',diff)
  const { data: venueData, isSuccess: isSuccess2, isError } = useFetchVenuesByIdsQuery((diffRef.current.length > 0) ? diffRef.current : skipToken);
  useEffect(() => {
    if (isSuccess2) {
      dispatch(setSuccess)
      dispatch(upserVenues(venueData));
    };
    if (isError){
      dispatch(setError)
    }
    //update the venues in the map
    const venues = selectVenueSubset(store.getState(), requestedVenueIds)
    setLiveVenues(venues);
    if (debug) console.log(liveVenues);
    dispatch(updateVenueData(venues));
  }, [ isSuccess2, isError, venueData, requestedVenueIds]);

//need to add methods to expire this upperlevel cacheing
return null
};
GetVenues();
if (debug) console.log(liveVenueRef);
///Venue stuff end

  return (
    <StyledMapContainer>
      <MapMarkersPopups  id="fyeMap" {...viewState} {...baseSettings} data={venues} onMove={onMove} mapStyle={THEMES.streets} />
    </StyledMapContainer>
  );
}
