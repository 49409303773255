import { useEffect } from 'react'
import useState from 'react-usestateref';
import { skipToken } from '@reduxjs/toolkit/query/react'
import { store } from '../../app/store';
import { useAppSelector, useAppDispatch } from '../../app/hooks';
import { useGetMediaQuery } from './mediaApi'
import { setSuccess, setError, upsertMedia, selectMediaDifference, selectMediaSubset, selectedMedia } from './mediaSlice';
// import { addManyMediaFilters } from './mediaFiltersSlice'
import { updateGridData } from '../grid/gridSlice'

const debug = false;

const Media = () => {
  const dispatch = useAppDispatch();
  const [diff, setDiff, diffRef] = useState([]);
  const { requestedMediaIds } = useAppSelector(selectedMedia);

  // check for differnce bettween the reurned media Ids from the Map api call and what is the Media cache
  useEffect(() => {
      // @ts-ignore
    setDiff(selectMediaDifference(store.getState(), requestedMediaIds));
  }, [setDiff, requestedMediaIds]);
  // get the media that are the difference
  if (debug) console.log('diff', diff)
  const { data, isSuccess, isError } = useGetMediaQuery((diffRef.current.length > 0) ? diffRef.current : skipToken);
  // upsert the addtional media if there are more and display the subset of media in the grid
  useEffect(() => {
    if (isSuccess) {
      dispatch(setSuccess)
      dispatch(upsertMedia(data));
      // dispatch(addManyMediaFilters(data));
    };
    if (isError) {
      dispatch(setError)
    }
    const media = selectMediaSubset(store.getState(), requestedMediaIds)
    dispatch(updateGridData(media));
  }, [dispatch, data, isSuccess, isError, requestedMediaIds]);

  // need to add methods to expire this upperlevel cacheing

  return null
};

// need to add methods to expire this upperlevel cacheing

export default Media
