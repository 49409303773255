import { Key } from 'react';
import styled from "styled-components";
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import {
  useAppSelector
} from '../../app/hooks';
import { selectedGrid } from './gridSlice';

require("./Grid.css");

const GridContainer = styled.div`
position: fixed; no-repeat;
top: 64px;
bottom: 0;
left: 50%;
right: 0;
width: 50%;
`;

export default function Grid() {
  const { data: assets } = useAppSelector(selectedGrid);

  return (
        <GridContainer>
          <ImageList sx={{ width: 'auto', height: 'auto' }} cols={3} gap={2} rowHeight='auto'>
            {assets.map((media: { id: Key | null | undefined; tile: any; name: string | undefined; }) => (
              <ImageListItem key={media.id}>
                <img
                  src={`${media.tile}?w=300&h=300&fit=crop&auto=format`}
                  srcSet={`${media.tile}?w=300&h=300&fit=crop&auto=format&dpr=2 2x`}
                  alt={media.name}
                  loading="lazy"
                />
              </ImageListItem>
            ))}
          </ImageList>
        </GridContainer>
  );
}
